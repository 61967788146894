import React,{useRef} from 'react'
import env from 'react-dotenv';
import { Link, useNavigate } from 'react-router-dom';
import { friend_reason_list, user_logout } from '../../../services/user_apis';
import { notification_list, notification_change_status, mark_as_read ,survey_details,submit_survey} from '../../../services/user_apis';
import $ from 'jquery'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import  NotificationSound from "../../../notification_sound.mp3"
import Swal from 'sweetalert2';
import Modal from '../modal/Modal';


const Home_Header = ({ socketData }) => {

    const audioPlayer = useRef(null);

    const { t, i18n } = useTranslation();
    const [notificationlist, setNotificationList] = React.useState([]);
    const [notificationCount, setNotificationCount] = React.useState("");
    const [friendreasonList, setFriendReasonList] = React.useState([])
    const [sendRequestData, setSendRequestData] = React.useState(null)

    const navigate = useNavigate()
    let languages = [
        { id: 1, name: "English", value: "en" },
        { id: 3, name: "عربي", value: "ar" },
        { id: 3, name: "हिंदी", value: "hin" },
    ]

    const user = JSON.parse(localStorage.getItem('user'))


    const logout = async () => {
        localStorage.removeItem('user');
        localStorage.removeItem('isLogin');
        localStorage.removeItem('language');
        localStorage.removeItem('userId');
        localStorage.removeItem("nCount");
        await user_logout(user)
        let path = window.location.protocol + "//" + window.location.host + ""
        window.location.href = path;
        // if (response.code === 1) {
        //     localStorage.removeItem('user');
        //     localStorage.removeItem('isLogin');
        //     localStorage.removeItem('language');
        //     localStorage.removeItem('userId');
        //     localStorage.removeItem("nCount");
        //     let path = window.location.protocol + "//" + window.location.host + ""
        //     window.location.href = path;
        // }

    }

    React.useEffect(() => {
        myNotification();
    }, [])

    React.useEffect(() => {
        // myNotification()
        //friend_list_reason_api()
        let seleted_language = localStorage.getItem("language")
        $("#" + seleted_language).attr("selected", true)
        const interval = setInterval(() => {
            myNotification()
        }, 15000);
        return () => clearInterval(interval);
    }, [])


    const myNotification = async () => {
        const response = await notification_list({ unread: true })
        // console.log('response', response);
        if (response.code === 1) {
            var oldCount=localStorage.getItem("nCount");

            setNotificationList(response.data.slice(0, 4))
            setNotificationCount(response.data.filter((item) => item.is_unread === 1))
           var countNew=response.data.filter((item) => item.is_unread === 1).length;
            
           if(countNew!=oldCount){
                localStorage.setItem("nCount",countNew);
                audioPlayer.current.play();
            }

        } else if (response.code === 2) {
            setNotificationList([])
        }
    }

    function changeLanguage(e) {
        i18n.changeLanguage(e.target.value);
        localStorage.setItem("language", e.target.value)
    }

    const redirectNotification = (item) => {
        if (item.action === "add_announcement") {
            navigate("/my-class")
        } else if (item.action === "add_homework") {
            navigate("/my-homework")
        } else if (item.action === "post_comment") {
            navigate(`/comment/${item.data.post_id}`)
        }else if(item.action === "post"){
            navigate(`/post/${item.data.post_id}`)
        }else if (item.action=="online_class"){
            navigate("/my-homework")
        }
        else if(item.action=="homework_submission"){
            navigate("/my-homework-teacher")
        }
        else if(item.action === "survey"){
            callAPISurveyCheck(item)
        }else if(item.action=="add_report"){
            if( user.user_role === "teacher"){
                navigate("/reportAction")
            }
        }else if(item.action=="offline_chat"){
            onClickClariaBot();
        }
    }

    const onClickClariaBot = (event) => {
        $('.clarie').removeClass('clarie-active')
        $('.chat-with-friend').addClass('chat-with-desk-friend-active')
    }

    const callAPISurveyCheck= async (item) => {
        const response = await survey_details({"survey_id":item.action_id})
        if (response.code === 1) {
            showSurveyPopup(response.data)
        }
        
    }

    const showSurveyPopup = (item)=>{
        Swal.fire({
            title: item.title,
            text: item.description,
            input: 'radio',
            inputOptions: {"1":item.option1,"2":item.option2,"3":item.option3,"4":item.option4},
            inputValidator: (value) => {
              if (!value) {
                return t('You need to choose something!')
              }
            }
          }).then((result) => {
            setSurveyUser(item,result.value)
          })
    }

    const setSurveyUser = async(item,value)=>{
        var useranswer=item.option4
        if(value === 1){
            useranswer=item.option1
        }else if(value === 2) {
            useranswer=item.option2
        }
        else if(value === 3) {
            useranswer=item.option3
        }                
        var userid=localStorage.getItem("userId");

        const response = await submit_survey({"surevy_id":item.id,"user_id":userid,"user_answer":useranswer})
        if (response.code === 1) {
            Swal.fire({
                title: 'Cybersmarties',
                text: response.message,
                confirmButtonText: 'Okay'
              })
        }
    }

    const callMarkAsAllRead = async () => {
        const response = await mark_as_read()
        setNotificationCount("");
        if (response.code === 1) {
            myNotification()
        } else if (response.code === 2) {
            myNotification()
        }
    }

    const searchButton= (event)=>{
        navigate("/add-classmate-search",{state:{"data":document.getElementById("searchData").value}})
    }

    const notificationChangeStatus = async (userid, status, notificatonid) => {
        const user = JSON.parse(localStorage.getItem('user'))
        const post_body = {
            from_user_id: userid,
            friend_status: status,
            notification_id: notificatonid,
            school_id: user.school_id
        }
        const response = await notification_change_status(post_body)
        if (response.code === 1) {
            myNotification()
        } else if (response.code === 2) {
            myNotification()
        }
        if (status === "accepted") {
            setTimeout(() => {
                // console.log("socket exicute")
                socketData(userid)
            }, 2000);
        }
    }

    const friend_list_reason_api = async () => {
        const response = await friend_reason_list()
        if (response.code === 1) {
            setFriendReasonList(response.data)
            // console.log("friend reason list   ====> ", response.data)
        }

    }

    const setData = async (item) => {
        friend_list_reason_api()
        setSendRequestData(item)
    }

    return (
        <React.Fragment>
            <audio ref={audioPlayer} src={NotificationSound} />
            <header>
                <div className="h-header">
                    <div className="logo">

                    </div>
                    <div className="search-content">
                        {
                            user.user_role === "teacher" ?
                            <></>
                            :
                            <>
                                <div className="search-box">
                                    <input className="search" placeholder={t("Search Friends")} id="searchData" />
                                </div>
                                <div className="search-position">
                                    <button className="search-icon" onClick={searchButton}></button>
                                </div>
                            </>
                        }        
                    </div>
                    <div className="social">
                        <div className="search search-header"><a href="#"><img src={"assets/web/search-1.png"} /> {t('Search')}</a>
                        </div>
                        <div className="form-group m-0 mr-3">
                            <div className="report-message-dropdown ">
                                <select className="form-control reportMessage" onChange={changeLanguage}>
                                    {languages.map((item, i) => (
                                        <option key={i} value={item.value} id={item.value}>{item.name}</option>
                                    ))}

                                </select>
                            </div>
                        </div>
                        {/* <div className="msg"><Link to="/message"><img src={`${process.env.PUBLIC_URL + "/" + env.MSG_PNG}msg.png`} /> Message</Link>
                        </div> */}
                        <div className="notification">
                            <div className="comment-right">
                                <div className="more notification_more">

                                    <div className="notify-list more-option text-start">
                                    {notificationlist.length > 0 ?
                                            notificationlist.map((item, i) => {
                                                // {console.log(item.action)}
                                                if (item.action === "friend_request") {
                                                    return (
                                                        <div className=" w-100 my-1 px-5 py-2 box" key={i}>
                                                            <div className="flex-nowrap row alignItems-center">
                                                                <img src={typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.user_profile_full_path !== 'undefined' ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                <div className='w-100'>
                                                                    <p className="card-text">{`${t(item.notification_message)}`}</p>
                                                                    {item.data.status === "accepted" ?
                                                                        <p>{t('Request Accepted')}</p>
                                                                        :
                                                                        <>
                                                                            <button onClick={() => setData(item)} className="btn btn-success mx-1" data-target="#friend-RequestModal" data-toggle="modal">{t('Accept')}</button>
                                                                            <button onClick={() => notificationChangeStatus(item.data.from_user_id, "rejected", item.notification_id)} className="btn btn-danger mx-1">{t('Reject')}</button>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                else if (item.action === "online_class") {
                                                    return (
                                                        <>
                                                        <Link to={"/my-homework"} state={{ homworkData: item.data }} key={i}>
                                                            <div className={` w-100 my-1 px-5 py-2 box ${item.is_unread === 1 ? "unread" : "read"} `} key={i}>
                                                                <div className="flex-nowrap row alignItems-center">
                                                                    <img src={typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.user_profile_full_path !== 'undefined' ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                    <div className='w-100'>
                                                                        <p className="card-text">{`${item.notification_title} ${t('from')} ${typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : (typeof item.sender_detail.username !== 'undefined' ? item.sender_detail.username: '')} `}</p>
                                                                        <p className="card-text">{`${item.notification_message}`}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        </>
                                                    );
                                                }
                                                else if (item.action === "add_homework") {
                                                    return (
                                                        <>
                                                        <Link to={"/homeworkDetails"} state={{ homworkData: item.data }} key={i}>
                                                            <div className={` w-100 my-1 px-5 py-2 box ${item.is_unread === 1 ? "unread" : "read"} `} key={i}>
                                                                <div className="flex-nowrap row alignItems-center">
                                                                    <img src={typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.user_profile_full_path !== 'undefined' ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                    <div className='w-100'>
                                                                        <p className="card-text">{`${item.notification_title} ${t('from')} ${typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : (typeof item.sender_detail.username !== 'undefined' ? item.sender_detail.username: '')} `}</p>
                                                                        <p className="card-text">{`${item.notification_message}`}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        </>
                                                    );
                                                }
                                                else if (item.action === "add_report") {
                                                    return (
                                                        <>
                                                        <Link to={"/reportAction"} state={{ homworkData: item.data }} key={i}>
                                                            <div className={` w-100 my-1 px-5 py-2 box ${item.is_unread === 1 ? "unread" : "read"} `} key={i}>
                                                                <div className="flex-nowrap row alignItems-center">
                                                                    <img src={typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.user_profile_full_path !== 'undefined' ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                    <div className='w-100'>
                                                                        <p className="card-text">{`${item.notification_title} ${t('from')} ${typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : (typeof item.sender_detail.username !== 'undefined' ? item.sender_detail.username: '')} `}</p>
                                                                        <p className="card-text">{`${item.notification_message}`}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        </>
                                                    );
                                                }
                                                else if (item.action === "homework_submission") {
                                                    return (
                                                        <>
                                                        <Link to={user.user_role === "student" ? "/homeworkDetails" : "/home-work-submit-details"} state={{ homworkData: item.data }}>
                                                            <div className={` w-100 my-1 px-5 py-2 box ${item.is_unread === 1 ? "unread" : "read"} `} key={i}>
                                                                <div className="flex-nowrap row alignItems-center">
                                                                    <img src={typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.user_profile_full_path !== 'undefined' ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                    <div className='w-100'>
                                                                        <p className="card-text">{`${item.notification_title} ${t('from')} ${typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : (typeof item.sender_detail.username !== 'undefined' ? item.sender_detail.username: '')} `}</p>
                                                                        <p className="card-text">{`${item.notification_message}`}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        </>
                                                    );
                                                }
                                                else if (item.action === "post_comment") {
                                                    return (<>
                                                        <div onClick={() => redirectNotification(item)} className={` w-100 my-1 px-5 py-2 box ${item.is_unread == 1 ? "unread" : "read"} `} >
                                                            <div class="flex-nowrap row align-items-center">
                                                                <img src={typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.user_profile_full_path !== 'undefined' ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                <div className='w-100'>
                                                                    <p class="card-text">{`${typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : (typeof item.sender_detail.username !== 'undefined' ? item.sender_detail.username: '')} ${t('has')} ${item.notification_title}`}</p>
                                                                    <p class="card-text">{`${item.notification_message}`}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>);
                                                }
                                                else {
                                                    return (<>
                                                        <div onClick={() => redirectNotification(item)} className={` w-100 my-1 px-5 py-2 box ${item.is_unread == 1 ? "unread" : "read"} `} >
                                                            <div class="flex-nowrap row align-items-center">
                                                                <img src={typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.user_profile_full_path !== 'undefined' ? item.sender_detail.user_profile_full_path : "assets/web/friend.png"} style={{ width: 65, height: 65 }} />
                                                                <div className='w-100'>
                                                                    <p class="card-text">{`${item.notification_title} ${t('from')} ${typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.display_user_name !== 'undefined' ? item.sender_detail.display_user_name : (typeof item.sender_detail !== 'undefined' && typeof item.sender_detail.username !== 'undefined' ? item.sender_detail.username: '')} `}</p>
                                                                    <p class="card-text">{`${item.notification_message}`}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>);
                                                }
                                            })
                                        :
                                            <div className="non-content text-center my-2">
                                                {(user.user_role === 'student') ?
                                                    <>
                                                        <img src={`${process.env.PUBLIC_URL + "/" + env.FEELINGS_PNG}friend.png`} />
                                                        <p>
                                                            {t('No New Friend Requests')}
                                                        </p>
                                                        <p>
                                                            {t('There are no new friend requests at this moment')}
                                                        </p>
                                                    </>
                                                    :
                                                    <p>{t('There are no new notifications at this moment')}</p>
                                                }
                                            </div>
                                        }
                                        <div className="view-all m-1">
                                            <div>
                                                <Link to="/notification" className='text-center' onClick={() => $(".notify-list").removeClass("active")}>
                                                    {t('View All Updates')}
                                                </Link>
                                            </div>
                                            <div>
                                                <a href='javaScript:' className='text-center' onClick={() => callMarkAsAllRead()}>
                                                    {t('Mark All As Read')}
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="javascript:" >
                                        <img src={`${process.env.PUBLIC_URL + "/" + env.FEELINGS_PNG}notification.png`} />
                                        {t('Notifications')}
                                        <div className='notification-badge'><p className='badge-text'>{notificationCount.length}</p></div>
                                    </a>
                                </div>
                            </div>
                            
                            {/* <Link to="/notification"><img src={`${process.env.PUBLIC_URL + "/" + env.NOTIFICATION_PNG}notification.png`} />
                                Notifications</Link> */}
                            {/* <a href="javascript:" onClick={() => $(".notify-list").toggleClass("active")}><img src={`${process.env.PUBLIC_URL + "/" + env.FEELINGS_PNG}notification.png`} />
                                Notifications</a> */}

                        </div>
                        <Link to="/" onClick={() => logout()} style={{ textDecoration: "none" }}>

                            <img src={`${process.env.PUBLIC_URL + "/" + env.LOGOUT_PNG}logout.png`} /> {t('Logout')}

                        </Link>
                        <div className="toggle"><a href="#"><img src={`${process.env.PUBLIC_URL + "/" + env.MENU_PNG}menu.png`} />
                            {t('Menu')}</a></div>
                    </div>

                </div>
            </header>
            {sendRequestData ?
                <Modal isAcceptRequest={true} socketData={socketData} friend_reason_list={friendreasonList} send_friend_request_data={sendRequestData} isUpdate={(data) => {setSendRequestData(null); data === true ? myNotification(): setSendRequestData(null)}} onPrimaryButtonClick={()=> {setSendRequestData(null); myNotification()}}/>
            :null}
        </React.Fragment>
    )
}

export default Home_Header


import React, { useEffect } from "react";
import env from "react-dotenv";
import Home_Header from "../../inc/home-header/Home_Header";
import Left_Panel from "../left-panel/Left_Panel";
import PostListing from "../home/PostListing";
import { todayHomework } from "../../common";
import { getUserdata } from "../local-storage/Local_StorageFn";
import rect1 from "./home-img/rectangle_4.png";
import rect2 from "./home-img/rectangle_4.png";
import rect3 from "./home-img/rectangle_5.png";
import rect4 from "./home-img/rectangle_6.png";
import rect5 from "./home-img/rectangle_7.png";
import rect6 from "./home-img/rectangle_8.png";
import { Button } from "@mui/material";
import { InputLabel } from "@mui/material";
import { MenuItem } from "@mui/material";
import { FormHelperText } from "@mui/material";
import { FormControl } from "@mui/material";
import { Select } from "@mui/material";
import { Link } from "react-router-dom";
import $ from 'jquery';
// import { format, render, cancel, register } from 'timeago.js';
// import { makeStyles } from '@material-ui/core/styles';
import { add_post } from "../../../services/user_apis";
import { cheer_me_up, post_list_api_school, teacher_class_division_list, teacher_list, create_chat_room, get_general_notifications } from "../../../services/user_apis";
import { like_dislike } from "../../../services/user_apis";
import { CircularProgress } from "@mui/material";
// import format from 'timeago';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Modal from "../../inc/modal/Modal";

const Home = ({ socketData, homeworknotice, chatHandle }) => {
    const { t } = useTranslation();
    const [post_list, setPost_list] = React.useState([])
    const [ datalimit, setDatalimit ] = React.useState({ limit: 10, page: 1 });
    const [category, setcategory] = React.useState('class');
    const [teacherlist, setTeacherlist] = React.useState([]);
    const [desc, setDesc] = React.useState('');
    const [circular, setcircular] = React.useState(true)
    const [reportPostData, setReportPostData] = React.useState("")
    let [cheermeup, setCheermeup] = React.useState([]);
    let [reportType, setReportType] = React.useState("");
    const user = JSON.parse(localStorage.getItem('user'))
    let [class_listall, setClassList] = React.useState([])
    const [classIdR,setClassIdR] =React.useState("")
    const [divisionIdR,setDivisionIdR] =React.useState("")
    const [schoolIdR,setSchoolIdR] =React.useState("")

    const [categoryTeacher, setcategoryTeacher] = React.useState('school');
    const [categoryTeacherClassId, setcategoryTeacherClassId] = React.useState('');
    const [categoryTeacherDivisionId, setcategoryTeacherDivisionId] = React.useState('');
    const [generalNotificationData, setGeneralNotificationData] = React.useState([]);

    let navigate = useNavigate();
    useEffect(() => {
        post_list1()
    }, [datalimit])

    useEffect(() => {
        class_list()
        chatHandle(true);        
        generalNotificationList();
    }, []);

    const generalNotificationList = async () => {
        let post_body = {
            user_role: user.user_role,
            classes: user.classes
        }
        const response = await get_general_notifications(post_body)
        if (response.code === 1) {
            setGeneralNotificationData(response.data)
        }
    }

    const class_list = async () => {
        if (user.user_role === "teacher") {
            const response = await teacher_class_division_list()
            if (response.code === 1) {
                setClassList(response.data)
                setcategoryTeacher("school")
                setcategoryTeacherClassId(response.data[0].class_id)
                setcategoryTeacherDivisionId(response.data[0].division_id)
                teacherAlllist();
            }
        }
    }

    const post_list1 = async () => {
        try {
            const school_id = await user.school_id;
            const response = await post_list_api_school({...{ "school_id": school_id }, ...datalimit})
            if (response.code === 1) {
                setPost_list(response.data)
            }
        }
        catch (e) {
            navigate('/')
        }
        setcircular(false)
    }

    // const useStyles = makeStyles((theme) => ({
    //     formControl: {
    //         margin: theme.spacing(1),
    //         minWidth: 80,
    //     },
    //     selectEmpty: {
    //         marginTop: theme.spacing(2),
    //     },
    // }));





    // const classes = useStyles();
   
    const handleChange = (event) => {
        // console.log(event.target.value, "handle change categroy")
        setcategory(event.target.value);
    };

    const handleChangeTeacher = (event) => {
        setcategoryTeacher(event.target.value);
        if (event.target.value !== 'home' && event.target.value !== 'school') {
            var dataList=class_listall.filter((item)=>(item.class_name+" - "+item.division_name === event.target.value))
            setcategoryTeacher('class');
            setcategoryTeacherClassId(dataList[0].class_id)
            setcategoryTeacherDivisionId(dataList[0].division_id)
        }

    };


    const handledesc = async (e) => {
        // console.log('post text:', e.target.value)
        setDesc(e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />'))
    }
    const handleAddPost = async () => {
// console.log('posting post');
        try {
            if (!circular) {
                $('.post-comment input').val('');
                $('.post-comment input').focus();
                setcircular(true)
                let description = desc
                const post_body = {
                    school_id: user.school_id,
                    class_id: user.class_id,
                    division_id: user.division_id,
                    post_category: category,
                    post_description: description
                }
                const resp = await add_post(post_body)
                if ((resp.code == "1") && user) {
                    const response = await post_list_api_school({...{ "school_id": user.school_id }, ...datalimit})
                    if (response.code === 1) {
                        setPost_list(response.data)
                    }
                    setDesc("");
                    setcircular(false)
                } else {
                    setcircular(false);
                }
            }
        } catch (e) {
            setcircular(false)
        }
    }


    const handleAddPostTeacher = async () => {
        setcircular(true)
        let description = desc
        const post_body = {
            school_id: user.school_id,
            class_id: categoryTeacherClassId,
            division_id: categoryTeacherDivisionId,
            post_category: categoryTeacher,
            post_description: description
        }
        const resp = await add_post(post_body)
        if ((resp.code == "1") && user) {
            const response = await post_list_api_school({...{ "school_id": user.school_id }, ...datalimit})
            if (response.code === 1) {
                setPost_list(response.data)
            }
            setDesc("");
            setcircular(false)
        }
       
    }


    const cheerMeUp = async () => {
        const response = await cheer_me_up()
        if (response.code === 1) {
            setCheermeup(response.data)
        }
    }

    const updateLimit = () => {
        setcircular(true)
        setDatalimit({...datalimit, "limit": Number(datalimit.limit) + 10 })
    }

    const pagignation = () => {
        if (circular) {
            return <CircularProgress />
        }
        if (!circular) {
            if (post_list.length < datalimit.limit) {
                return <div className="no-more-activities">{t('No more activities found.')}</div>
            }
            return <Button variant="outlined" size="medium" onClick={updateLimit}>{t('See More')}</Button>
        }
    }

    const teacherAlllist = async () => {
        let post_body = {
            school_id: user.school_id,
        }
        const response = await teacher_list(post_body)
        if (response.code === 1) {
            setTeacherlist(response.data)
        } else {
            setTeacherlist([])
        }

    }

    const getPassDataOtherUser = (data) => {
        var dataPass = { "user_id": data.teacher_id, "username": data.username, "display_user_name": data.display_user_name, "user_about_me": data.about_me, "avatar_full_path": data.avatar }
        return dataPass
    }

    const createChatRoom = async (receiver_user) => {
        let post_body = {
            sender_id: user.user_id,
            sender_type: user.user_role,
            receiver_type: "teacher",
            school_id: user.school_id,
            receiver_id: receiver_user.teacher_id,
        }
        const response = await create_chat_room(post_body)
        if (response.code === 1) {
            setTimeout(() => {
                // console.log("socket execute")
                socketData(receiver_user.teacher_id)
            }, 1000);
            $(".chat-with-friend").addClass("chat-with-desk-friend-active");
        }
    }

    const teacherOptionSelcted = (value) => {
        if (categoryTeacher === value) return 'selected';
    }

    return (
        <React.Fragment>
            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <a href="#" className="chat-icon-mobile">
                        <img src={`${env.CHAT_ICON_PNG}chat-icon.png`} />
                    </a>
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-8 pr-md-0" >
                                <div className="post-comment">
                                    {
                                        user.user_role === "student" ? 
                                        <>
                                        <select className="post-selction" value={category} onChange={handleChange}>
                                            {/* <option value={"home"}>Home</option> */}
                                            <option value={"class"}>{t('Class')}</option>
                                            <option value={"school"}>{t('School')}</option>
                                        </select>
                                        </>
                                        :
                                        <>
                                    <select className="post-selction" defaultValue={categoryTeacher} onChange={handleChangeTeacher}>
                                        <option value={"school"}>{t('School')}</option>
                                        {
                                            class_listall.map((item, index) => (
                                                <option key={index} value={item.class_name+" - "+item.division_name}>{item.class_name+" - "+item.division_name}</option>
                                                ))
                                        }
                                    </select>
                                        </>
                                    }                                
                                    {/* <textarea
                                        type="textarea"
                                        placeholder="Write something here..." value={desc} onChange={handledesc}
                                    /> */}
                                    <input
                                        type="text"
                                        placeholder={t("Write something here...")} value={desc} onChange={handledesc}
                                    />
                                    {
                                        user.user_role === "student" ? 
                                        <>
                                        <button className="post" onClick={handleAddPost}>{t('Share')}</button>
                                        </>
                                        :
                                        <>
                                        <button className="post" onClick={handleAddPostTeacher}>{t('Share')}</button>
                                        </>
                                    }       


                                </div>

                                <div className="right-msg1 mobile-cheermeup">
                                    <div className="msg-title">
                                        {t('Are you Feeling Sad?')}
                                    </div>
                                    <div className="msg-text">
                                        {t('If you are feeling sad we can cheer up with a small surprise')}
                                    </div>

                                    <button
                                        className="right-btn"
                                        data-toggle="modal"
                                        data-target="#feel_Modal"
                                    >
                                        {t('Cheer Me Up')}
                                    </button>

                                    <div className="feelings">
                                        <img
                                            src={`${env.FEELINGS_PNG}feelings.png`}
                                        />
                                    </div>
                                </div>

                                <div className="post-profile">

                                </div>
                                <PostListing data={post_list}
                                    callBackData={(data) => {
                                        setReportType(data.type)
                                        setReportPostData(data.reportdata)
                                    }}
                                />
                                <br/>
                                {pagignation()}
                                <br/>
                                <br/>
                            </div>
                            <div className="col-lg-4">
                                {/* <div className="chat-with-friend chat-mob">
                                    <div className="chat-head">
                                        <h3>Chat with Friends (5)</h3>
                                    </div>
                                    <div className="chat-body">
                                        <div className="swiper chat-swiper">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <a href="#">
                                                        <img src={`${env.PRO_IMG}pro-img.png`} />
                                                        Jennifer
                                                    </a>
                                                </div>
                                                <div className="swiper-slide">
                                                    <a href="#">
                                                        <img src={`${env.PRO_IMG}pro-img.png`} />
                                                        Cathy
                                                    </a>
                                                </div>
                                                <div className="swiper-slide">
                                                    <a href="#">
                                                        <img src={`${env.PRO_IMG}pro-img.png`} />
                                                        Courtney
                                                    </a>
                                                </div>
                                                <div className="swiper-slide">
                                                    <a href="#">
                                                        <img src={`${env.PRO_IMG}pro-img.png`} />
                                                        Jennifer
                                                    </a>
                                                </div>
                                                <div className="swiper-slide">
                                                    <a href="#">
                                                        <img src={`${env.PRO_IMG}pro-img.png`} />
                                                        Jennifer
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="right-side text-left">
                                    {user.user_role === 'teacher' &&
                                        <div className="member-right">
                                        <h3>{t('Teachers in your School')} ({teacherlist.length})</h3>
                                            <div className="mem-list">
                                                <ul>
                                                    {teacherlist && teacherlist.map((value, i) => (
                                                        <li key={i}>
                                                            <div className='myclass-member-view add-friend-section'>
                                                                <img src={value?.avatar != null ? `${value?.avatar}` : `${env.PRO_IMG}pro-img.png`} />

                                                                <Link to="/otherUserProfile" state={{ "otherUserData": getPassDataOtherUser(value) }}>
                                                                    <p>{value?.display_user_name != "" ? value?.display_user_name : value?.username}</p>
                                                                </Link>
                                                                {value.teacher_id != user.user_id &&
                                                                    <button onClick={() => createChatRoom(value)} className="add-friend">
                                                                        {t('Chat')}
                                                                    </button>}
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>
                                    }
                                    {user.user_role === "student" &&
                                        <>
                                            <div className="right-msg1">
                                                <div className="msg-title">
                                                    {t('Are you Feeling Sad?')}
                                                </div>
                                                {cheermeup != "" &&
                                                    <>
                                                        <div className="msg-text ">
                                                            {t('We are sorry you are feeling sad. Everyone feels sad sometimes but look at the nice words one of your friends said about you.')}
                                                        </div>
                                                        <h6 className='mt-3'> {cheermeup?.display_user_name != "" ? cheermeup?.display_user_name : cheermeup?.username} {t('says,')} {cheermeup?.cheer_up_message}</h6>
                                                    </>
                                                }
                                                <button className="right-btn mt-2" onClick={() => cheerMeUp()}>{t('Cheer Me Up')}</button>

                                            </div>
                                            <div className="feelings">
                                                <img
                                                    src={`${env.FEELINGS_PNG}feelings.png`}
                                                />
                                            </div>
                                            {todayHomework(homeworknotice)}
                                            {/* <div className="article-box">
                                                <h3>Good News Channel</h3>
                                                <div className="article">
                                                    <div className="article-img">
                                                        <img src="assets/web/article-img.png" />
                                                    </div>
                                                    <div className="article-detail">
                                                        <p>UAE’s Hope Mission to Mars launches; astronauts complete space walk, prepare for splashdown</p>
                                                        <div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="article-btn">
                                                    <a href="#" className="btn btn-article">Read Article</a>
                                                </div>
                                            </div> */}

                                            <div className="activity-box">
                                                <Link to="/funezone" state={{ activeTab: "gameTab", key: "game" }} className="game-activity funzone-game" >
                                                    <h4 className={(generalNotificationData?.funzone?.game > 0) ? "notify" : ""}>{t('Games')}</h4>
                                                    {generalNotificationData && (generalNotificationData?.funzone?.game > 0) && <div className="new_notify jsBlink"><p className="badge-text">{t('New')}</p></div>}
                                                </Link>
                                                <Link to="/funezone" state={{ activeTab: "cartoonTab", key: "cartoon" }} className="game-activity funzone-cartoon" >
                                                    <h4 className={(generalNotificationData?.funzone?.cartoon > 0) ? "notify" : ""}>{t('Cartoons')}</h4>
                                                    {generalNotificationData && (generalNotificationData?.funzone?.cartoon > 0) && <div className="new_notify jsBlink"><p className="badge-text">{t('New')}</p></div>}
                                                </Link>
                                                <Link to="/funezone" state={{ activeTab: "wellBeingTab", key: "well_being" }} className="game-activity funzone-wellBeing" >
                                                    <h4 className={(generalNotificationData?.funzone?.well_being > 0) ? "notify" : ""}>{t('Wellbeing')}</h4>
                                                    {generalNotificationData && (generalNotificationData?.funzone?.well_being > 0) && <div className="new_notify jsBlink"><p className="badge-text">{t('New')}</p></div>}
                                                </Link>
                                                <Link to="/funezone" state={{ activeTab: "memesTab", key: "meme" }} className="game-activity funzone-memes" >
                                                    <h4 className={(generalNotificationData?.funzone?.meme > 0) ? "notify" : ""}>{t('Memes')}</h4>
                                                    {generalNotificationData && (generalNotificationData?.funzone?.meme > 0) && <div className="new_notify jsBlink"><p className="badge-text">{t('New')}</p></div>}
                                                </Link>
                                                {/* <Link to="/funezone" state={{ activeTab: "codingTab", key: "coding" }} className="game-activity funzone-coding" >
                                                    <h4 className={(generalNotificationData?.funzone?.coding > 0) ? "notify" : ""}>{t('Coding')}</h4>
                                                    {generalNotificationData && (generalNotificationData?.funzone?.coding > 0) && <div className="new_notify jsBlink"><p className="badge-text">{t('New')}</p></div>}
                                                </Link> */}
                                                <Link to="/funezone" state={{ activeTab: "videoTab", key: "video" }} className="game-activity funzone-videos" >
                                                    <h4 className={(generalNotificationData?.funzone?.video > 0) ? "notify" : ""}>{t('Videos')}</h4>
                                                    {generalNotificationData && (generalNotificationData?.funzone?.video > 0) && <div className="new_notify jsBlink"><p className="badge-text">{t('New')}</p></div>}
                                                </Link>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal report_type={reportType} report_data={reportPostData} isUpdate={(data) => data === true && setReportPostData("")} />
            </div>
        </React.Fragment >
    );
};

export default Home;